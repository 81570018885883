.ant-checkbox-group.ant-checkbox-group-small .ant-checkbox-group-item {
  width: 50%;
  /* Set the width as needed to control the number of checkboxes per line */
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.compliance-list {
  list-style: none; }

.compliance-card h3 {
  font-weight: 500; }

.ant-checkbox-wrapper span {
  font-weight: 500; }

.note-save-box {
  float: right;
  clear: both; }

.mt-1 {
  margin-top: 5px; }
