.ant-upload-select {
  width: 100% !important;
  height: auto !important; }

.front_image_help {
  text-align: center; }

.back_image_help {
  text-align: center; }

.remove-img-btn {
  margin-top: 10px;
  text-align: center; }

.uploaded-image {
  max-width: 100%;
  height: auto;
  display: block; }
